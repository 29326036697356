/*
Lapero Codery - A three.js application by Lapero.io
Version 1.0
*/

import './style.css'
import "@lottiefiles/lottie-player";

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { SpotLight, Vector3 } from 'three'
import * as lil from 'lil-gui'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

let clock, controls, camera, scene, composer, renderer, mixer = [], action, manager

manager = new THREE.LoadingManager()
const loadingElement = document.querySelector('div.loading')
manager.onLoad = () => {
    loadingElement.classList.add("loaded")
}

let emptyRotationOne, emptyRotationTwo, emptyRotationThree, emptyRotationFour
let flowerEmptyOne, flowerEmptyTwo
let modelHeaderText, modelBodyText, modelFooterText
let areaLightRight, areaLightLeft
let textVerticalPositions = {
    header: 6.56,
    body: 5.08,
    footer: 2.88,
}

const colors = {
    purple: 0x6a12e9, // hsl(265/360 0.74, 86%, 49%)
    red: 0xEC008C, // hsl(324/360 0.9, 100%, 46%)
    yellow: 0xffcc00, // hsl(48/360 0.134, 100%, 50%)
    blue: 0x21ffe1,
    white: 0xffffff,
    black: 0x000000,
    gray: 0x636363,
}
// const colorHSLCalc = (percent) => {
//     const l = 0.9 // First value in color range in Hue wheel
//     const r = 0.234 // percent (range) of hue wheel to cycle through
//     const m = percent * r
//     const v = l + m
//     if ( v > 1 ) {
//         return v - 1
//     } 
//     return v
// }

// Bloom parameters
const params = {
    exposure: 0.4,
    bloomThreshold: 0.25,
    bloomStrength: 0.94,
    bloomRadius: 0.4,
    x: 0,
    y: 0,
    z: 0,
};

const init = () => {
    
    // Canvas
    const canvas = document.querySelector('canvas.webgl')
    
    scene = new THREE.Scene()

    const color = 0x000000;  // white
    const near = 10;
    const far = 120;

    scene.fog = new THREE.Fog(color, near, far)
    scene.background = new THREE.Color( 0x000000 )


    /**************
     * Empties
     **************/
    //#region
    emptyRotationOne = new THREE.Object3D()
    emptyRotationOne.position.set(0,3,0)
    scene.add(emptyRotationOne)

    emptyRotationTwo = new THREE.Object3D()
    emptyRotationTwo.position.set(0,2,0)
    scene.add(emptyRotationTwo)

    emptyRotationThree = new THREE.Object3D()
    emptyRotationThree.position.set(0,-20,0)
    scene.add(emptyRotationThree)

    emptyRotationFour = new THREE.Object3D()
    emptyRotationFour.position.set(0,10,0)
    scene.add(emptyRotationFour)

    flowerEmptyOne = new THREE.Object3D()
    flowerEmptyOne.position.set(-4,0,0)
    emptyRotationThree.add(flowerEmptyOne)
    
    flowerEmptyTwo = new THREE.Object3D()
    flowerEmptyTwo.rotation.x = Math.PI * 0.2
    flowerEmptyOne.add(flowerEmptyTwo)
    //#endregion

    /**************
     * Models
     **************/
    //#region
        const gltfLoader = new GLTFLoader( manager )

        // Atrium
        let modelAtrium
        gltfLoader.load(
            './gltf/Atrium.gltf',
            (gltf) =>
            {
                modelAtrium = gltf.scene
                modelAtrium.position.y = -20
                scene.add(modelAtrium)
            }
        )

        // Pedestal
        let modelPedestal
        gltfLoader.load(
            './gltf/Pedestal.gltf',
            (gltf) =>
            {
                modelPedestal = gltf.scene
                modelPedestal.position.y = -3
                scene.add(modelPedestal)
            }
        )

        // Venus Statue - Animated
        let modelVenus
        gltfLoader.load(
            './gltf/Venus_Marble.gltf',
            (gltf) =>
            {
                modelVenus = gltf.scene
                modelVenus.position.y = -3
                scene.add(modelVenus)

                let index = mixer.length
                mixer.push( new THREE.AnimationMixer( modelVenus ) )
                gltf.animations.forEach( (clip) => {
                    action = mixer[index].clipAction( clip )
                })
                action.play();
            }
        )

        // Butterfly - Animated
        let modelButterfly
        gltfLoader.load(
            './gltf/Butterfly.gltf',
            (gltf) =>
            {
                modelButterfly = gltf.scene
                modelButterfly.position.set(3,0,0)
                emptyRotationOne.add(modelButterfly)

                let index = mixer.length
                mixer.push( new THREE.AnimationMixer( modelButterfly ) )
                gltf.animations.forEach( (clip) => {
                    action = mixer[index].clipAction( clip )
                })
                action.play();
            }
        )

        // Flower
        let modelFlower
        gltfLoader.load(
            './gltf/Flower.gltf',
            (gltf) =>
            {
                modelFlower = gltf.scene
                modelFlower.position.set(0,0,0)
                modelFlower.scale.set(0.7, 0.7, 0.7)
                flowerEmptyTwo.add(modelFlower)
            }
        )

        // Geometry 
        let modelGeometry
        gltfLoader.load(
            './gltf/Geometry.gltf',
            (gltf) =>
            {
                modelGeometry = gltf.scene
                modelGeometry.position.set(-4.5,-1,0)
                
                const emissiveMaterial = new THREE.MeshStandardMaterial({color: colors.white, emissive: colors.white})
                modelGeometry.traverse((o) => {
                    if (o.isMesh) o.material = emissiveMaterial
                })
                emptyRotationTwo.add(modelGeometry)
            }
        )

        // Illuminated Lapero Logo
        let modelLogo
        gltfLoader.load(
            './gltf/Codery_logo_2023.gltf',
            (gltf) =>
            {
            modelLogo = gltf.scene
            modelLogo.position.set(0,8.5,-1)
            // modelLogo.rotation.y = Math.PI * 1.5
                
                const emissiveMaterial = new THREE.MeshStandardMaterial({color: colors.white, emissive: colors.white})
                modelLogo.traverse((o) => {
                    if (o.isMesh) o.material = emissiveMaterial
                })
                scene.add(modelLogo)
            }
        )

        // Text Header
        gltfLoader.load(
            './gltf/Text_header.gltf',
            (gltf) =>
            {
            modelHeaderText = gltf.scene
            modelHeaderText.position.set(-3.02,textVerticalPositions.header,0)
                
                const emissiveMaterial = new THREE.MeshStandardMaterial({color: colors.gray, emissive: colors.gray})
                modelHeaderText.traverse((o) => {
                    if (o.isMesh) o.material = emissiveMaterial
                })
                scene.add(modelHeaderText)
            }
        )

        // Text Body       
        gltfLoader.load(
            './gltf/Text_body.gltf',
            (gltf) =>
            {
            modelBodyText = gltf.scene
            modelBodyText.position.set(1.16,textVerticalPositions.body,1.64)
                
                const emissiveMaterial = new THREE.MeshStandardMaterial({color: colors.gray, emissive: colors.gray})
                modelBodyText.traverse((o) => {
                    if (o.isMesh) o.material = emissiveMaterial
                })
                scene.add(modelBodyText)
            }
        )

         // Text Footer
         gltfLoader.load(
             './gltf/Text_footer.gltf',
             (gltf) =>
             {
             modelFooterText = gltf.scene
             modelFooterText.position.set(-3.52,textVerticalPositions.footer,-0.82)
                 
                 const emissiveMaterial = new THREE.MeshStandardMaterial({color: colors.gray, emissive: colors.gray})
                 modelFooterText.traverse((o) => {
                     if (o.isMesh) o.material = emissiveMaterial
                 })
                 scene.add(modelFooterText)
             }
         )

    //#endregion

    /**********
     * Lights
     **********/
    //#region
        const spotlightTarget = new THREE.Object3D()
        spotlightTarget.position.set(0,40,0)
        scene.add( spotlightTarget )

        const spotLight = new THREE.SpotLight( colors.white, 3, 150, 2.5, 1.0 )
        spotLight.position.set(0, 10, 0)
        spotLight.target = spotlightTarget
        scene.add(spotLight)

        areaLightRight = new THREE.RectAreaLight(colors.red, 9, 10, 10)
        areaLightRight.position.set(10, 0, 0)
        areaLightRight.lookAt(0,0,0)
        emptyRotationFour.add(areaLightRight)

        areaLightLeft = new THREE.RectAreaLight(colors.yellow, 9, 10, 10)
        areaLightLeft.position.set(-10, 0, 0)
        areaLightLeft.lookAt(0,0,0)
        emptyRotationFour.add(areaLightLeft)
    //#endregion


    /***********************
     * Camera / Rendering
     ***********************/
    //#region
        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        // Base camera
        camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000)
        camera.position.x = 0
        camera.position.y = 3
        camera.position.z = 10
        scene.add(camera)
        
        // Controls
        controls = new OrbitControls(camera, canvas)
        controls.target = new Vector3(0, 3, 0)
        controls.enableDamping = true
        controls.autoRotate = false
        controls.minDistance = 5
        controls.maxDistance = 15
        controls.enablePan = false
        
        // Renderer
        renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            alpha: true,
            antialias: true
        })
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        renderer.setClearColor( 0x000000, 1)
        
        // Animate
        clock = new THREE.Clock()

        const renderScene = new RenderPass( scene, camera )

        // Bloom
        const bloomPass = new UnrealBloomPass( new THREE.Vector2(sizes.width, sizes.height), 1.5, 0.4, 0.85)
        bloomPass.threshold = params.bloomThreshold
        bloomPass.strength = params.bloomStrength
        bloomPass.radius = params.bloomRadius

        composer = new EffectComposer( renderer )
        composer.addPass( renderScene )
        composer.addPass( bloomPass )

        window.addEventListener('resize', () => {
            // update window sizes
            sizes.width = window.innerWidth
            sizes.height = window.innerHeight

            camera.aspect = sizes.width / sizes.height
            camera.updateProjectionMatrix()

            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

            composer.setSize(sizes.width, sizes.height)
            
        })
    //#endregion

    /*******
     * GUI
     *******/
    //#region
        // const gui = new lil.GUI({ closed: true })

        // gui.add( params, 'x', -10, 10 ).onChange( function ( value ) {

        //     modelFooterText.position.x = Number( value )

        // } );

        // gui.add( params, 'y', -10, 10 ).onChange( function ( value ) {

        //     modelFooterText.position.y = Number( value )

        // } );

        // gui.add( params, 'z', -10, 10 ).onChange( function ( value ) {

        //     modelFooterText.position.z = Number( value )

        // } );
    //#endregion
}

const animate = () => {

    const elapsedTime = clock.getElapsedTime()

    // Rotate and move parent empties up and down.
    if ( emptyRotationOne ) {
        emptyRotationOne.rotation.y += 0.015
        emptyRotationOne.position.y = Math.sin(elapsedTime) + 5
    }
    if ( emptyRotationTwo ) {
        emptyRotationTwo.rotation.y += 0.005
        emptyRotationTwo.position.y = Math.sin(elapsedTime / 2) + 5
    }
    if ( emptyRotationThree ) {
        emptyRotationThree.rotation.y += 0.0038
        emptyRotationThree.position.y = Math.sin(elapsedTime / 1.8) + 1
    }

    // Flower Rotation Effects
    if ( flowerEmptyOne ) {
        flowerEmptyOne.rotation.y -= 0.02
        if ( flowerEmptyTwo ) {
            flowerEmptyTwo.rotation.y += 0.01
        }
    }

    // Text Floating effects
    if ( modelHeaderText ) {
        modelHeaderText.position.y = ( Math.cos(elapsedTime + 3.5) / 10 ) + textVerticalPositions.header
    }
    if ( modelBodyText ) {
        modelBodyText.position.y = ( Math.cos(elapsedTime / 0.97) / 10 ) + textVerticalPositions.body
    }
    if ( modelFooterText ) {
        modelFooterText.position.y = ( Math.cos(elapsedTime + 2) / 10 ) + textVerticalPositions.footer
    }

    // Light Hue rotation
    // if ( emptyRotationFour ) {
    //     emptyRotationFour.rotation.y += 0.003
    // }
    // const hue1 = (Math.sin(elapsedTime / 10) + 1) / 2
    // const hue2 = (Math.sin((elapsedTime / 10) + 5) + 1) / 2
    // areaLightLeft.color.setHSL( colorHSLCalc(hue1), 1.0, 0.5)
    // areaLightRight.color.setHSL( colorHSLCalc(hue2), 1.0, 0.5)
}


const tick = () => {
    const delta = clock.getDelta()
    
    if ( mixer.length ) mixer.forEach( m => m.update( delta ) )
    
    animate()
    
    // Update controls
    controls.update()

    // Render
    // renderer.render(scene, camera)
    composer.render()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

init()
tick()
